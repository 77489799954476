/*****************************************************************************
 * UmanIT - Appears on scroll
 *****************************************************************************/

let bricks = document.querySelectorAll("[data-animate='smooth-appearing']");

if(breakpoints.isDesktop()) {
  addSmoothClasses();
  checkScrollGaps();
  document.addEventListener("scroll", function() {
    checkScrollGaps();
  });
}

window.addEventListener("resize", function () {
  setTimeout(function () {
    if(breakpoints.isDesktop()) {
      addSmoothClasses();
      checkScrollGaps();
      document.addEventListener("scroll", function () {
        checkScrollGaps();
      });
    } else {
      removeSmoothClasses();
    }
  }, 100);
});

/**
 * Fonction qui détecte la position du parent conteneur par rapport au scroll dans le viewport, et ajoute les classes CSS d'apparition si le conteneur est à plus de 300px du bord inférieur de la fenêtre
 *
 * return {void}
 */
function checkScrollGaps() {
  bricks.forEach(function(brick) {
    const container = brick.closest("[data-animate='smooth-appearing-container']");
    const containerOffset = container.getBoundingClientRect();
    const top = containerOffset.top;
    const scrollGap = window.innerHeight - 300;

    if (top < scrollGap) {
      brick.classList.add("smooth-appearing-visible");
    }
  });
}

/**
 * Fonction qui ajoute les classes CSS pour la préparation de l'animation
 *
 * return {void}
 */
function addSmoothClasses() {
  bricks.forEach(function(brick) {
    brick.classList.add("smooth-appearing");

    let animationDirection = brick.dataset.animation;
    switch (animationDirection) {
      case "smooth-appearing--from-right" :
        brick.classList.add("smooth-appearing--from-right");
        break;
      case "smooth-appearing--from-bottom" :
        brick.classList.add("smooth-appearing--from-bottom");
        break;
      case "smooth-appearing--from-left" :
        brick.classList.add("smooth-appearing--from-left");
        break;
      default:
        brick.classList.add("smooth-appearing--from-bottom");
    }
  });
}

function removeSmoothClasses() {
  bricks.forEach(function(brick) {
    if(brick.classList.contains("smooth-appearing")) {
      brick.classList.remove("smooth-appearing");
    }
    if(brick.classList.contains("smooth-appearing--from-right")) {
      brick.classList.remove("smooth-appearing--from-right");
    }
    if(brick.classList.contains("smooth-appearing--from-bottom")) {
      brick.classList.remove("smooth-appearing--from-bottom");
    }
    if(brick.classList.contains("smooth-appearing--from-left")) {
      brick.classList.remove("smooth-appearing--from-left");
    }

  });
}

